var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"realEstatePanel"},[(_vm.loaded)?_c('div',{staticClass:"mt-2"},[_c('validation-observer',{ref:"refFormObserver"},[_c('div',{staticClass:"d-flex"},[_c('feather-icon',{attrs:{"icon":"PackageIcon","size":"19"}}),_c('h4',{staticClass:"mb-0 ml-50"},[_vm._v(" "+_vm._s(_vm.msg('New real estate'))+" ")])],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"cols":"12","md":"3","lg":"3"}},[_c('b-form-group',{attrs:{"label":_vm.msg('Building'),"label-for":"product-branch"}},[_c('validation-provider',{attrs:{"name":_vm.msg('Building'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"w-100",attrs:{"dir":_vm.isRTL ? 'rtl' : 'ltr',"options":_vm.allBuildings,"label":"address","reduce":function (building) { return building.id; },"clearable":false},model:{value:(_vm.realEstate.buildingId),callback:function ($$v) {_vm.$set(_vm.realEstate, "buildingId", $$v)},expression:"realEstate.buildingId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1120148611)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"3","lg":"3"}},[_c('b-form-group',{attrs:{"label":_vm.msg('Building'),"label-for":"producgdgfs"}},[_c('b-button',{on:{"click":_vm.goToNewBuilding}},[_vm._v(_vm._s(_vm.msg('New building')))])],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"3"}},[_c('b-form-group',{attrs:{"label":_vm.msg('Name'),"label-for":"realEstate-name"}},[_c('validation-provider',{attrs:{"name":_vm.msg('Name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"realEstate-nffdame","state":errors.length > 0 ? false:null},model:{value:(_vm.realEstate.name),callback:function ($$v) {_vm.$set(_vm.realEstate, "name", $$v)},expression:"realEstate.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3107223642)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"3"}},[_c('b-form-group',{attrs:{"label":_vm.msg('Address'),"label-for":"realEstate-name"}},[_c('validation-provider',{attrs:{"name":_vm.msg('Address'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"realEstate-name","state":errors.length > 0 ? false:null},model:{value:(_vm.realEstate.address),callback:function ($$v) {_vm.$set(_vm.realEstate, "address", $$v)},expression:"realEstate.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3683005359)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"3"}},[_c('b-form-group',{attrs:{"label":_vm.msg('House number'),"label-for":"realEstate-name"}},[_c('validation-provider',{attrs:{"name":_vm.msg('House'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"realEstate-name","type":"number","state":errors.length > 0 ? false:null},model:{value:(_vm.realEstate.house),callback:function ($$v) {_vm.$set(_vm.realEstate, "house", $$v)},expression:"realEstate.house"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,692574640)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"3"}},[_c('b-form-group',{attrs:{"label":_vm.msg('Apt'),"label-for":"realEstate-name"}},[_c('validation-provider',{attrs:{"name":_vm.msg('Apt'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"realEstate-name","type":"number","state":errors.length > 0 ? false:null},model:{value:(_vm.realEstate.apt),callback:function ($$v) {_vm.$set(_vm.realEstate, "apt", $$v)},expression:"realEstate.apt"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1599364273)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"3"}},[_c('b-form-group',{attrs:{"label":_vm.msg('Branch'),"label-for":"product-branch"}},[_c('validation-provider',{attrs:{"name":_vm.msg('Branch'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"w-100",attrs:{"dir":_vm.isRTL ? 'rtl' : 'ltr',"options":_vm.allBranches,"label":"name","reduce":function (branch) { return branch.id; },"clearable":false},model:{value:(_vm.realEstate.buid),callback:function ($$v) {_vm.$set(_vm.realEstate, "buid", $$v)},expression:"realEstate.buid"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,160859060)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"3"}},[_c('b-form-group',{attrs:{"label":_vm.msg('Usage type'),"label-for":"realEstate-type"}},[_c('validation-provider',{attrs:{"name":_vm.msg('Usage'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"w-100",attrs:{"dir":_vm.isRTL ? 'rtl' : 'ltr',"options":[
                    {value:{business:false,investment:false,living:true,waz:false},label:_vm.msg('Living')}
                    ,{value:{business:true,investment:false,living:false,waz:false},label:_vm.msg('Business')},
                    {value:{business:false,investment:true,living:false,waz:false},label:_vm.msg('Investment')}],"label":"label","reduce":function (status) { return status.value; }},on:{"option:selected":_vm.onValueUpdated},model:{value:(_vm.realEstate.usageType),callback:function ($$v) {_vm.$set(_vm.realEstate, "usageType", $$v)},expression:"realEstate.usageType"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1566813429)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"3"}},[_c('b-form-group',{attrs:{"label":_vm.msg('Real estate type'),"label-for":"realEstate-type","description":_vm.msg('For rent or sale?')}},[_c('validation-provider',{attrs:{"name":_vm.msg('Type'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-select',{staticClass:"w-100",attrs:{"dir":_vm.isRTL ? 'rtl' : 'ltr',"options":[{value:{purchase:false,rent:true},label:_vm.msg('Rent')},{value:{purchase:true,rent:false},label:_vm.msg('Purchase')}],"label":"label","reduce":function (status) { return status.value; }},model:{value:(_vm.realEstate.marketingType),callback:function ($$v) {_vm.$set(_vm.realEstate, "marketingType", $$v)},expression:"realEstate.marketingType"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,683887874)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"3"}},[_c('b-form-group',{attrs:{"label":_vm.msg('Rent (for long term rent)'),"label-for":"realEstate-name"}},[_c('validation-provider',{attrs:{"name":_vm.msg('Price'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"realEstate-name","type":"number","step":"0.5","state":errors.length > 0 ? false:null},model:{value:(_vm.realEstate.realEstatePrices.rentTotal),callback:function ($$v) {_vm.$set(_vm.realEstate.realEstatePrices, "rentTotal", $$v)},expression:"realEstate.realEstatePrices.rentTotal"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1342835436)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"3"}},[_c('b-form-group',{attrs:{"label":_vm.msg('Owner ID'),"label-for":"realEstate"}},[_c('client-picker',{attrs:{"is-owner":""},model:{value:(_vm.realEstate.ownerId),callback:function ($$v) {_vm.$set(_vm.realEstate, "ownerId", $$v)},expression:"realEstate.ownerId"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"3"}},[_c('b-form-group',{attrs:{"label":_vm.msg('Base rent price per night (base for the next 2 years)'),"label-for":"realEstate-ndgdfame"}},[_c('validation-provider',{attrs:{"name":_vm.msg('Base price'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"realEstate-name","type":"number","step":"0.5","state":errors.length > 0 ? false:null},model:{value:(_vm.realEstate.realEstatePrices.basePricePerNight),callback:function ($$v) {_vm.$set(_vm.realEstate.realEstatePrices, "basePricePerNight", $$v)},expression:"realEstate.realEstatePrices.basePricePerNight"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,967662976)})],1)],1)],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"3"}},[_c('b-form-group',{attrs:{"label":_vm.msg('Floor'),"label-for":"realEstate-name"}},[_c('b-form-input',{attrs:{"id":"realEstate-name","type":"number"},model:{value:(_vm.realEstate.floorNumber),callback:function ($$v) {_vm.$set(_vm.realEstate, "floorNumber", $$v)},expression:"realEstate.floorNumber"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"5","lg":"3"}},[_c('b-form-group',{attrs:{"label":_vm.msg('Floor text'),"label-for":"realEstate-name"}},[_c('b-form-input',{attrs:{"id":"realEstate-name"},model:{value:(_vm.realEstate.floor),callback:function ($$v) {_vm.$set(_vm.realEstate, "floor", $$v)},expression:"realEstate.floor"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"3"}},[_c('b-form-group',{attrs:{"label":_vm.msg('Rooms'),"label-for":"realEstate-name"}},[_c('validation-provider',{attrs:{"name":_vm.msg('Rooms'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"realEstate-name","type":"number","min":"0","step":"1","state":errors.length > 0 ? false:null},model:{value:(_vm.realEstate.realEstateArea.rooms),callback:function ($$v) {_vm.$set(_vm.realEstate.realEstateArea, "rooms", $$v)},expression:"realEstate.realEstateArea.rooms"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3501721872)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"3"}},[_c('b-form-group',{attrs:{"label":_vm.msg('Peoples'),"label-for":"realEstate-name"}},[_c('validation-provider',{attrs:{"name":_vm.msg('Peoples'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"realEstate-name","type":"number","min":"0","step":"1","state":errors.length > 0 ? false:null},model:{value:(_vm.realEstate.peoples),callback:function ($$v) {_vm.$set(_vm.realEstate, "peoples", $$v)},expression:"realEstate.peoples"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3164450589)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"3"}},[_c('b-form-group',{attrs:{"label":_vm.msg('Total space'),"label-for":"realEstate-name"}},[_c('validation-provider',{attrs:{"name":_vm.msg('total space'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"realEstate-name","type":"number","min":"0","step":"0.1","state":errors.length > 0 ? false:null},model:{value:(_vm.realEstate.realEstateArea.totalSpace),callback:function ($$v) {_vm.$set(_vm.realEstate.realEstateArea, "totalSpace", $$v)},expression:"realEstate.realEstateArea.totalSpace"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2524000260)})],1)],1)],1)],1)],1):(this.error != null)?_c('div',[_c('b-alert',{attrs:{"show":"","variant":"danger"}},[_c('i',{staticClass:"fas fa-exclamation-triangle mr-3"}),_c('strong',[_vm._v(_vm._s(_vm.error))])])],1):_c('div',[_c('div',{staticStyle:{"min-height":"300px","text-align":"center"}},[_c('div',{staticStyle:{"margin-top":"20%"}},[_c('b-spinner',{attrs:{"label":"Spinning"}}),_vm._v(" "+_vm._s(_vm.msg('loading'))+" ")],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }